import React, { useEffect } from 'react'
import { ReduxTypes } from '../type_models/ReduxTypes'
import { useDispatch, useSelector } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer'
import { getCachTotal } from '../actions/MainAction'
import MainTop from '../components/layouts/MainTop'
import TableView from '../components/layouts/TableView'
import GraphicView from '../components/layouts/GraphicView'

function CashPage() {
  const tableVisible = useSelector((state:ReduxTypes)=> state.Data.tableVisible);
  const cashTotalData = useSelector((state:ReduxTypes)=> state.Data.cashTotalData);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    const params = {"type":"Cash","period_type":3, "number_of_days":60, "company_id": "", "cash_id": "", "currencyID":"bc1e1899-6b2d-11ed-a3d9-b47af12e823d"};
    dispatch(getCachTotal(params))
    return () => {
      dispatch(changeStateValue({ name: "tableVisible", value: false }));
    };
  }, []);
  // const data = [
  //     { name: "Diger xercler", value: 9236548, color: "#F5C242" },
  //     { name: "Diger xercler", value: 1696548, color: "#F2A73B" },
  //     { name: "Diger xercler", value: 1396548, color: "#EA334B" },
  //     { name: "Diger xercler", value: 129248, color: "#AE59DC" },
  //     { name: "Diger xercler", value: 1196548, color: "#5B61D6" },
  //     { name: "Diger xercler", value: 1696548, color: "#57BEB5" },
  //     { name: "Diger xercler", value: 192348, color: "#3070F5" },
  //   ];
  
  const getDataFormat = (data:any) => {
    const colors = ["#F5C242", "#F2A73B", "#EA334B", "#AE59DC", "#5B61D6", "#57BEB5", "#3070F5"]
    let arr:any = [];
    for(const item of data){
      arr.push({
        name:"Diger xercler",
        value: item?.sum,
        color: colors[Math.floor(Math.random()*7)]
      })
    }
    return arr;
  }
  return (
    <>
      <MainTop/>
      <>
        {
          tableVisible?  <TableView/> : <GraphicView totalData={getDataFormat(cashTotalData)}/>
        }
      </>
    </>
  )
}

export default CashPage
