import { useDispatch, useSelector } from 'react-redux';
import { ArshiveBookSvg, Bank1Svg, BriefCaseSvg, CallSvg, CloseSvg, EmailSvg, UserSvg, VOENSvg, WhatsappSvg } from '../../assets';
import { ReduxTypes } from '../../type_models/ReduxTypes';
import { changeStateValue } from '../../redux/MainReducer';

function ModalInfo() {
  const modalInfoVisible = useSelector((state: ReduxTypes) => state.Data.modalInfoVisible)
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    if (modalInfoVisible) {
      dispatch(changeStateValue({ name: "modalInfoVisible", value: false }));
    } else {
      dispatch(changeStateValue({ name: "modalInfoVisible", value: true }));
    }
  };
  return (
    <div className={modalInfoVisible ? 'background' : ''}>
      <div
        className='modal-info'
        style={{ bottom: modalInfoVisible ? "0" : '-100%' }}
      >
        <div className="modal-info-top">
          <h2>Haqqinda</h2>
          <div className="close-modal">
            <button className='whatsapp'>
              <WhatsappSvg />
            </button>
            <button className='close' onClick={() => dispatch(changeStateValue({ name: "modalInfoVisible", value: false }))}>
              <CloseSvg />
            </button>
          </div>
        </div>
        <div className="modal-info-main">
          <ul>
            <li>
              <div className="left">
                <VOENSvg />
                <h6>Vöen:</h6>
              </div>
              <div className="right">
                <h6>2132569659663</h6>
              </div>
            </li>
            <li>
              <div className="left">
                <Bank1Svg />
                <h6>Əsas bank hesabı:</h6>
              </div>
              <div className="right">
                <h6>Kapital Bank:</h6>
                <span>2132569659663</span>
              </div>
            </li>
            <li>
              <div className="left">
                <Bank1Svg />
                <h6>Hesablaşma hesabı:</h6>
              </div>
              <div className="right">
                <h6>Paşa Bank:</h6>
                <span>2132569659663</span>
              </div>
            </li>
            <li>
              <div className="left">
                <ArshiveBookSvg />
                <h6>Hüquqi ünvan:</h6>
              </div>
              <div className="right">
                <h6>Bakı.N.Nərimano Şərifzadə N15</h6>
              </div>
            </li>
            <li>
              <div className="left">
                <EmailSvg />
                <h6>Email ünvanı:</h6>
              </div>
              <div className="right">
                <h6>siesco@gmail.com</h6>
              </div>
            </li>
            <li>
              <div className="left">
                <CallSvg />
                <h6>Əlaqə nömrəsi:</h6>
              </div>
              <div className="right">
                <h6>050 632 52 63</h6>
              </div>
            </li>
            <li>
              <div className="left">
                <UserSvg />
                <h6>Direktorun adı, soyadı:</h6>
              </div>
              <div className="right">
                <h6>Şahin Fərəcov</h6>
              </div>
            </li>
            <li>
              <div className="left">
                <BriefCaseSvg />
                <h6>Rəhbərin vəzifəsi:</h6>
              </div>
              <div className="right">
                <h6>Direktor</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
