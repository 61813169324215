import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend
} from 'recharts';

interface DataItem {
  date: string;
  balance: number;
}

// Sample data
const data: DataItem[] = [
  { date: '1.10.2024', balance: 80 },
  { date: '2.10.2024', balance: 85 },
  { date: '3.10.2024', balance: 130 },
  { date: '4.10.2024', balance: 90 },
  { date: '5.10.2024', balance: 75 },
  { date: '6.10.2024', balance: 65 },
  { date: '7.10.2024', balance: 70 },
  { date: '8.10.2024', balance: 90 },
  { date: '9.10.2024', balance: 95 },
  { date: '10.10.2024', balance: 85 },
];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            background: '#EDEFF1',
            borderRadius: '12px 12px 0 0 ',
            padding: '8px',
          }}
        >
          <span style={{fontWeight:400}}>{date}</span>
        </div>
        <div
          style={{
            background: '#FFF',
            borderRadius: '0 0 12px 12px',
            padding: '8px',
            boxShadow: '0px 4px 10px 0px rgba(14, 73, 113, 0.10)',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#249BF4',
              borderRadius: '30px',
              marginRight: '8px',
            }}
          ></span>
          <strong>Balance:</strong> {balance} ₼
        </div>
      </div>
    );
  }

  return null;
};

const SingleBarChartComponent: React.FC = () => {
  const [barSize, setBarSize] = useState(35);
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize(10);
        setBarSize(10);
      } else if (window.innerWidth <= 800) {
        setFontSize(14);
        setBarSize(20);
      } else {
        setFontSize(16);
        setBarSize(35);
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      style={{ backgroundColor: '#fff' }}
      className="chart-card diagram-card"
    >
      <BarChart data={data}>
        <CartesianGrid className="single-diagram" strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={{ fill: '#A8A8A8' }} fontSize={fontSize} />
        <YAxis 
          tick={{ fill: '#A8A8A8' }}
          fontSize={fontSize} 
          width={window.innerWidth <= 600 ? 20 : 50 }
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend payload={[]} />
        <Bar dataKey="balance" fill="#249BF4" barSize={barSize} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SingleBarChartComponent;
