import React from 'react'

function ArshiveBookSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 30 30"
    >
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M26.25 8.75v12.5c0 3.75-1.875 6.25-6.25 6.25H10c-4.375 0-6.25-2.5-6.25-6.25V8.75C3.75 5 5.625 2.5 10 2.5h10c4.375 0 6.25 2.5 6.25 6.25Z"
        />
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M19.375 2.5v9.825c0 .55-.65.825-1.05.463l-2.9-2.675a.62.62 0 0 0-.85 0l-2.9 2.675c-.4.362-1.05.087-1.05-.463V2.5h8.75ZM16.563 17.5h5.312M11.25 22.5h10.625"
        />
    </svg>
  )
}

export default ArshiveBookSvg
