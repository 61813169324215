import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { moneyFormat } from "../../actions/OtherFunctions";

// const data = [
//   { name: "Diger xercler", value: 9236548, color: "#F5C242" },
//   { name: "Diger xercler", value: 1696548, color: "#F2A73B" },
//   { name: "Diger xercler", value: 1396548, color: "#EA334B" },
//   { name: "Diger xercler", value: 129248, color: "#AE59DC" },
//   { name: "Diger xercler", value: 1196548, color: "#5B61D6" },
//   { name: "Diger xercler", value: 1696548, color: "#57BEB5" },
//   { name: "Diger xercler", value: 192348, color: "#3070F5" },
// ];

// Custom Tooltip for styling


const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;

    return (
      <div style={{
        backgroundColor: '#EDEFF1', 
        borderRadius: '12px', 
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 4
      }}>
        <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>
          {name}
        </div>
        <div>
          {value.toFixed(2)} ₼
        </div>
      </div>
    );
  }
  return null;
};

const DonutChartExample = ({data=[]}:{data?:any}) => {
 
  const [fontSize, setFontSize] = useState('16px');
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize('12px');
      } else if (window.innerWidth <= 800) {
        setFontSize('14px');
      } else {
        setFontSize('16px');
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div  className="chart-card-circle chart-container" style={{backgroundColor:'white',position: 'relative'}}>
      <div style={{ textAlign: "center", position: 'relative', display: 'flex', justifyContent: 'center', backgroundColor:"white",alignItems: 'center' }}>
        <PieChart width={window.innerWidth <= 600 ? 200 : 350} height={window.innerWidth <= 600 ? 200 : 350}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            innerRadius={window.innerWidth <= 600 ? 70 : 115}
            outerRadius={window.innerWidth <= 600 ? 90 : 140}
            fill="#8884d8"
            paddingAngle={1}  
            style={{
              outline: 'none', // Directly apply to the chart
            }}
          >
            {data.map((entry:any, index:number) => (
              <Cell key={`cell-${index}`} fill={entry.color}  />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />}  />
        </PieChart>
        <div className='pieChartText'  style={{
          position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 0
        }}>
          <h3>Total Value</h3>
          <p>${moneyFormat(data.reduce((acc:any, item:any) => acc + item.value, 0))}</p>
        </div>
      </div>

      <div style={{ marginTop: 17 }}>
        {/* Scrollable legend for remaining items */}
        <div style={{ maxHeight: window.innerWidth <= 600 ?'150px': '172px', overflowY: 'auto', padding: '8px 6px' }} className="donut-scroll">
          {data.map((entry:any, index:number) => (
            <div key={index} className="donut-line" style={{ display: 'flex', alignItems: 'center', marginBottom: 12,padding:8 ,fontSize:fontSize}}>
              <div style={{ width: 12, height: 12, backgroundColor: entry.color, marginRight: 10 ,borderRadius: '2px',}}></div>
              <span style={{fontWeight: 600}}>{entry.name}</span>
              <span style={{ marginLeft: "auto" }}>{entry.value.toFixed(2)} ₼</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChartExample;