import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";

const username = '';
const password = '';
const token = btoa(`${username}:${password}`); 

export const getCachTotal = (params) => async dispatch => {
    return await axios.post(`${MAIN_API}CashTotal`,JSON.stringify(params),
        {
            headers:{
                "Authorization":  `Basic ${token}`,
                'Accept': 'application/json', // Match the server's response format
                'Content-Type': 'application/json',
            }
        }
    )
    .then(resp => {
        console.log(resp?.data)
        // dispatch(changeStateValue({
        //     name:"cashTotalData",
        //     value: resp?.data
        // }))
    }).catch(err=>{
        console.log(err.response)
    })
}