import React, { useState } from 'react';

function Months() {
  const [activeBtn, setActiveBtn] = useState<string>('last_30');

  const buttons = [
    { id: 'last_30', label: 'Son 30 gün' },
    { id: 'current_month', label: 'Cari ay' },
    { id: 'last_month', label: 'Keçən ay' },
  ];

  const handleClick = (id: string) => {
    console.log(`month: ${id}`);
    setActiveBtn(id);
  };

  return (
    <div className='months'>
      <ul className='months-list'>
        {buttons.map((button) => (
          <li
            key={button.id}
            onClick={() => handleClick(button.id)}
            className={activeBtn === button.id ? 'active-list' : ''}
          >
            <span>{button.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Months;
