import React, { useState } from 'react';
import { ArrowDownSvg, CurrencyAZNSvg, CurrencyEurSvg, CurrencyUsdSvg } from '../../../assets';

function Exchange() {
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<{ value: string; icon: JSX.Element }>({
    value: '1.00',
    icon: <CurrencyAZNSvg />,
  });

  const data = [
    { value: '1.00', icon: <CurrencyAZNSvg /> },
    { value: '1.78', icon: <CurrencyUsdSvg /> },
    { value: '1.99', icon: <CurrencyEurSvg /> },
  ];

  const handleSelect = (item: { value: string; icon: JSX.Element }) => {
    setSelectedCurrency(item);
    console.log(`valyuta: ${item.value}`);
    setDropVisible(false);
  };

  return (
    <div className="dropdown">
      <button
        onClick={() => setDropVisible(!dropVisible)}
        className={dropVisible ? 'dropdown-head dropdown-head_hover' : 'dropdown-head'}
      >
        <div className="dropdown-left">
          <div className="currency">
            {React.cloneElement(selectedCurrency.icon, {
              stroke: '#fff', 
              fill: '#fff',  
            })}
          </div>
          <span>{selectedCurrency.value}</span>
        </div>
        <div className="dropdown-svg">
          <ArrowDownSvg />
        </div>
      </button>
      {dropVisible && (
        <ul className="dropdown-content-currency visible">
          {data.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)}>
              <div className="currency">
                {React.cloneElement(item.icon, {
                  stroke: '#fff', 
                  fill: '#fff',   
                })}
              </div>
              <span>{item.value}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Exchange;
