import SingleChartComponent from "../chartSetting/SingleChartComponent"

function SingleGraficView() {
  return (
    <div className='one-charts'>
        <SingleChartComponent />
    </div>
  )
}

export default SingleGraficView
