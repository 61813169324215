import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxTypes } from '../../../type_models/ReduxTypes';
import { changeStateValue } from '../../../redux/MainReducer';
import { ArrowDownSvg, InfoCircleSvg } from '../../../assets';


function Companies(){
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const modalInfoVisible = useSelector((state: ReduxTypes) => state.Data.modalInfoVisible);
  const dispatch = useDispatch<any>();

  return (
    <div className="dropdown">
      <div
        className={dropVisible ? 'dropdown-head dropdown-head_hover' : 'dropdown-head'}
        onClick={() => setDropVisible(!dropVisible)}
      >
        <div className="dropdown-left">
          <button
            className="info-btn"
            onClick={(e) => {
              e.stopPropagation(); 
              dispatch(changeStateValue({ name: 'modalInfoVisible', value: !modalInfoVisible }));
            }}
          >
            <InfoCircleSvg />
          </button>
          <div className="dropdown-left">
            <span>Saat Store</span>
            <div className="dropdown-svg">
              <ArrowDownSvg />
            </div>
          </div>
        </div>
      </div>
      <ul className={`dropdown-content ${dropVisible ? 'visible' : ''}`}>
        {['Saat Store', 'Saat Store', 'Saat Store', 'Saat Store'].map((store, index) => (
          <li key={index}>
            <span>{store}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Companies;
