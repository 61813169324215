import React from 'react'
import MenuSvg from '../../assets/svg/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import logo from '../../assets/images/RedWhiteLogo.png'
import { RefreshSvg } from '../../assets';

function MobilNavbar() {
  const dispatch = useDispatch<any>();

  const handleRefresh = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log('Data refreshed');
    } catch (error) {
      console.error('Error refreshing data:', error);
    } 
  };
  return (
    <div className='mobile'>
      <div className='mob-navbar'>
        <button onClick={()=>dispatch(changeStateValue({name:"navVisible", value: true}))} >
            <MenuSvg color="#FFFFFF"/>
        </button>
        <div className='logo'>
            <img src={logo} alt="Logo"/>
          </div>
        <button  onClick={handleRefresh}>
            <RefreshSvg/>
        </button>
      </div>
    </div>
  )
}

export default MobilNavbar
