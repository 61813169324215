import React from 'react'

function BriefCaseSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 30 30"
        >
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M10 27.5h10c5.025 0 5.925-2.012 6.188-4.462l.937-10c.337-3.05-.538-5.538-5.875-5.538H8.75c-5.337 0-6.212 2.488-5.875 5.537l.938 10C4.075 25.488 4.975 27.5 10 27.5ZM10 7.5v-1c0-2.213 0-4 4-4h2c4 0 4 1.787 4 4v1"
        />
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M17.5 16.25v1.275C17.5 18.888 17.488 20 15 20c-2.475 0-2.5-1.1-2.5-2.462V16.25c0-1.25 0-1.25 1.25-1.25h2.5c1.25 0 1.25 0 1.25 1.25ZM27.063 13.75a20.605 20.605 0 0 1-9.563 3.775M3.275 14.087a20.354 20.354 0 0 0 9.225 3.45"
        />
    </svg>
  )
}

export default BriefCaseSvg
