import { CloseSvg } from "../../assets"

type Props = {
    closeModal: any
}

function MobileModalTop(props:Props) {
  return (
    <div className="modal-top">
        <h2>Şirkət seçin</h2>
        <button className="close-button" onClick={props.closeModal}>
            <CloseSvg />
        </button>
    </div>
  )
}

export default MobileModalTop
