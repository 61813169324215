import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DiagramSvg, MenuSvg } from '../../assets'
import { ReduxTypes } from '../../type_models/ReduxTypes'
import { changeStateValue } from '../../redux/MainReducer'

function MainTop() {
  const [changeBtn, setChangeBtn] = useState<boolean>(false)
  const tableVisible = useSelector((state: ReduxTypes) => state.Data.tableVisible)

  const dispatch = useDispatch<any>();

  return (
    <div className='main-top'>
      <h3>Ümumi vəsait: <span>15.730,00</span> ₼</h3>
      <button onClick={() => dispatch(changeStateValue({ name: 'tableVisible', value: !tableVisible }), setChangeBtn(!changeBtn))}>
        {
          changeBtn ?
            <>
              <span className='button-text'>Qrafik görünüşü</span>
              <DiagramSvg />
            </> :
            <>
              <span className='button-text'>Cədvəl görünüşü</span>
              <MenuSvg color='#FAFAFA' />
            </>
        }
      </button>
    </div>
  )
}

export default MainTop
