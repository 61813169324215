import ChartComponent from '../components/chartSetting/ChartComponent' 
import BarChartComponent from '../components/chartSetting/BarChartComponent' 

function HomePage() {
  return (
    <>
      <div className='charts'>
        <ChartComponent backgroundColor='#249BF4' title="Kassa"/>
        <BarChartComponent backgroundColor='#FF453D' title="Bank"/>
        <BarChartComponent backgroundColor='#8AB438' title="Debitor"/>
        <BarChartComponent backgroundColor='#DD3283' title="Kreditor"/>
        <ChartComponent backgroundColor='#FEA445' title="Pul silinmə"/>
        <ChartComponent backgroundColor='#8B56FB' title="Pul mədaxil"/>
        <BarChartComponent backgroundColor='#159888' title="Anbar qalığı"/>
        <ChartComponent backgroundColor='#EB3B6A' title="İstəhsal"/>
        <BarChartComponent backgroundColor='#249BF4' title="Alışlar"/>
        <BarChartComponent backgroundColor='#FEA445' title="Satışlar"/>
        <BarChartComponent backgroundColor='#8AB438' title="Vergilər  "/>
        <ChartComponent backgroundColor='#DD3283' title="Xərclər"/>
      </div>
    </>
  )
}

export default HomePage
