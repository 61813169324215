import React from 'react'

function EmailSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 30 30"
    >
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M21.25 25.625H8.75c-3.75 0-6.25-1.875-6.25-6.25v-8.75c0-4.375 2.5-6.25 6.25-6.25h12.5c3.75 0 6.25 1.875 6.25 6.25v8.75c0 4.375-2.5 6.25-6.25 6.25Z"
        />
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="m21.25 11.25-3.913 3.125c-1.287 1.025-3.4 1.025-4.687 0l-3.9-3.125"
        />
    </svg>
  )
}

export default EmailSvg
