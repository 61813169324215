

function CurrencyUsd() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={12}
    fill="none"
  >
    <path
      fill="#0D3558"
      d="M3.536 10.44a10.122 10.122 0 0 1-1.624-.168 6.458 6.458 0 0 1-1.302-.42V8.046c.383.187.845.36 1.386.518a7.41 7.41 0 0 0 1.54.266V6.716A16.102 16.102 0 0 1 2.15 6.1a4.28 4.28 0 0 1-.91-.616 2.133 2.133 0 0 1-.49-.728 2.592 2.592 0 0 1-.154-.924c0-.467.121-.868.364-1.204a2.63 2.63 0 0 1 1.036-.826 4.552 4.552 0 0 1 1.54-.378V.374h.938v1.022c.523.019 1.013.08 1.47.182.467.103.92.252 1.358.448l-.644 1.596a6.407 6.407 0 0 0-1.134-.364 7.862 7.862 0 0 0-1.05-.168v2.016c.504.187.985.392 1.442.616.457.215.826.49 1.106.826.29.336.434.78.434 1.33a2.23 2.23 0 0 1-.742 1.708c-.495.457-1.241.733-2.24.826v1.4h-.938V10.44Zm.938-1.652c.317-.056.55-.15.7-.28a.698.698 0 0 0 .224-.532.521.521 0 0 0-.112-.336.909.909 0 0 0-.308-.28 3.04 3.04 0 0 0-.504-.28v1.708Zm-.938-5.67a1.593 1.593 0 0 0-.476.14.722.722 0 0 0-.308.238.571.571 0 0 0-.098.336c0 .13.028.247.084.35.065.093.163.187.294.28.13.084.299.173.504.266v-1.61Z"
    />
  </svg>
  )
}

export default CurrencyUsd