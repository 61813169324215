import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { BarChartProps } from '../../type_models/PropsTypes';

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: '1', value: 30 },
  { name: '2', value: 70 },
  { name: '3', value: 20 },
  { name: '4', value: 90 },
  { name: '5', value: 50 },
  { name: '6', value: 40 },
  { name: '7', value: 60 },
  { name: '8', value: 20 },
  { name: '9', value: 80 },
  { name: '10', value: 60 },
];

const xAxisLabels = data.map((item) => item.name);
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
        <div
          style={{
            background: '#fff',
            borderRadius: '12px 12px 0 0',
            padding: '7px',
            paddingBottom: '2px',
            color:'#0D3558',
            fontSize: '9px'
          }}
        >
          <strong>Name: </strong> {name}
        </div>
        <div
          style={{
            background: '#fff',
            borderRadius: '0 0 12px 12px',
            color:'#0D3558',
            padding: '7px',
            paddingTop: '1px',
            boxShadow: '0px 4px 10px 0px rgba(14, 73, 113, 0.10)',
            fontSize: '9px'
          }}
        >
          <strong>Value: </strong> {value} ₼
        </div>
      </div>
    );
  }

  return null;
};


function BarChartComponent(props:BarChartProps) {
  const [fontSize, setFontSize] = useState('16px');
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize('16px');
      }  else {
        setFontSize('16px');
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div 
      style={{ backgroundColor: props.backgroundColor,cursor: 'pointer' }} 
      className="chart-card chart-card-shadow"
      onClick={()=>navigate('/cash')}
    >
      <div className="chart-info">
        <h3 style={{fontSize:fontSize,marginBottom: '8px'}}>{props.title}</h3>
        <p>₼361,454.00</p>
      </div>
      <ResponsiveContainer width="100%" maxHeight={250} height='80%' >
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="1 1" vertical={false} strokeWidth={1}  stroke={window.innerWidth <= 600 ? 'none' : '#fff'}  />
        <XAxis 
          dataKey="name" 
          tick={window.innerWidth <= 600 ? false :{ fill: '#fff' }}
          stroke={window.innerWidth <= 600 ? 'none' : '#fff'}
          fontSize={fontSize}
          height={window.innerWidth <= 600 ? 10 : 30 }
          ticks={xAxisLabels} 
        />
        <YAxis 
          tick={window.innerWidth <= 600 ? false :{ fill: '#fff' }}
          stroke="none"
          width={window.innerWidth <= 600 ? 0 :30 }
          fontSize={fontSize}
        />
        <Bar dataKey="value" fill="#ffffff" barSize={5}  />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(255, 255, 255, 0.2)' }} />
      </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
