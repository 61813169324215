import React from 'react'

function CloseSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#031C30"
    viewBox="0 0 32 32"
  >
    <g id="SVGRepo_iconCarrier">
      <defs>
        <style>
          {
            ".cls-1{fill:none;stroke:#031c30;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}"
          }
        </style>
      </defs>
      <title />
      <g id="cross">
        <path d="m7 7 18 18M7 25 25 7" className="cls-1" />
      </g>
    </g>
  </svg>
  )
}

export default CloseSvg
