import React from 'react'

function Bank1Svg() {
  return (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      stroke="#249BF4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="m15.463 2.688 11.25 4.5c.437.175.787.7.787 1.162v4.15c0 .688-.563 1.25-1.25 1.25H3.75c-.688 0-1.25-.563-1.25-1.25V8.35c0-.462.35-.987.788-1.162l11.25-4.5c.25-.1.675-.1.925 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M27.5 27.5h-25v-3.75c0-.688.563-1.25 1.25-1.25h22.5c.688 0 1.25.563 1.25 1.25v3.75Z"
    />
    <path
      stroke="#249BF4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M5 22.5v-8.75M10 22.5v-8.75M15 22.5v-8.75M20 22.5v-8.75M25 22.5v-8.75M1.25 27.5h27.5M15 10.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
    />
  </svg>
  )
}

export default Bank1Svg
