import { useState } from 'react';
import flagAZ from '../../../assets/images/flagAz.png';
import flagEN from '../../../assets/images/flagEn.png';
import flagRU from '../../../assets/images/flagRu.png';
import { ArrowDownSvg, LogOutSvg, PersonIDCardSvg } from '../../../assets';
import { LogOutProps } from '../../../type_models/PropsTypes';






function LogOut ({ groupName }:LogOutProps)  {
  const [dropVisible, setDropVisible] = useState<boolean>(false);
  const languages = [
    { code: 'AZ', name: 'AZ', flagSrc: flagAZ, altText: 'Azerbaijan flag' },
    { code: 'EN', name: 'EN', flagSrc: flagEN, altText: 'UK flag' },
    { code: 'RU', name: 'RU', flagSrc: flagRU, altText: 'Russian flag' },
  ];
  return (
    <div className="dropdown">
      <button
        onClick={() => setDropVisible(!dropVisible)}
        className={`dropdown-head-end ${dropVisible ? 'dropdown-head' : ''}`}
      >
        <PersonIDCardSvg />
        <span>{groupName}</span>
        <ArrowDownSvg />
      </button>
      <div className={`dropdown-content-logout ${dropVisible ? 'visible' : ''}`}>
        <div className="flags">
          {languages.map((lang) => (
            <div key={lang.code} className="flag">
              <div className="flag-circle">
                <img src={lang.flagSrc} alt={lang.altText} />
              </div>
              <p>{lang.name}</p>
            </div>
          ))}
        </div>
        <button className="logout-btn">
          <span>Çıxış</span>
          <LogOutSvg color="#0D3558" />
        </button>
      </div>
    </div>
  );
};

export default LogOut;
