export const initialState = {
    mobileDesign:false,
    navVisible:false,
    tableVisible:false,
    modalSelectVisible:false,
    modalSettingVisible:false,
    modalInfoVisible:false,
    headerVisible: false,
    minimize: false,

    cashTotalData: []
}