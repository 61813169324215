import { TableProps } from "../../type_models/PropsTypes";

function Table({ length }: TableProps) {
  return (
    <div className="table-card">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>Kassa adı</th>
              <th>Məbləğ</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length }, (_, index) => (
              <tr key={index} >
                <td>{index + 1}</td>
                <td>Content {index + 1}</td>
                <td>Content {index + 1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
