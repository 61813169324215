import Table from './Table'

function TableView() {
  return (
      <div className='table-view'>
        <Table length={5}/>
        <Table length={17}/>
      </div>
  )
}

export default TableView
