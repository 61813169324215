import { TotalDataProps } from "../../type_models/PropsTypes"
import DonutChartExample from "../chartSetting/DonutChartExample"
import SingleBarChartComponent from "../chartSetting/SingleBarChartComponent"


function GraphicView({ totalData }: TotalDataProps) {
  return (
    <div className='two-charts'>
      <DonutChartExample data={totalData} />
      <SingleBarChartComponent />
    </div>
  )
}

export default GraphicView
