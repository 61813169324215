import React from 'react'

function UserSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 30 30"
    >
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 15a6.25 6.25 0 1 0 0-12.5A6.25 6.25 0 0 0 15 15ZM25.738 27.5c0-4.837-4.813-8.75-10.738-8.75S4.263 22.663 4.263 27.5"
        />
    </svg>
  )
}

export default UserSvg
