import { ReduxTypes } from '../../type_models/ReduxTypes';
import { changeStateValue } from '../../redux/MainReducer'
import { useDispatch, useSelector } from "react-redux";
import MobileModalTop from "./MobileModalTop";
import { ArrowUpSvg, LogOutSvg, PersonIDCardSvg } from '../../assets';


function MobilSettingModal() {
  const modalSettingVisible = useSelector((state: ReduxTypes) => state.Data.modalSettingVisible)
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    if (modalSettingVisible) {
      dispatch(changeStateValue({ name: "modalSettingVisible", value: false }));
    } else {
      dispatch(changeStateValue({ name: "modalSettingVisible", value: true }));
    }
  };
  return (
    <div className={modalSettingVisible ? 'background' : ''}>
      <div
        className="fixed-bottom-modal"
        style={{ bottom: modalSettingVisible ? "0" : '-100%' }}
      >
        <div className="selection-head">
          <MobileModalTop closeModal={closeModal} />
          <div className="personal-logout">
            <div className="personal-info">
              <PersonIDCardSvg />
              <span className="group-name">Xan Group</span>
            </div>
            <button className="logout">
              <LogOutSvg color="#FAFAFA" />
            </button>
          </div>
        </div>

        <div className="flags-container">
          <div className="flag">
            <div className="flag-circle">
              <img src="./images/flag 1.png" alt="azerbaijan flag" />
            </div>
            <p>AZ</p>
          </div>
          <div className="flag">
            <div className="flag-circle">
              <img src="./images/united-kingdom 1.png" alt="US flag" />
            </div>
            <p>EN</p>
          </div>
          <div className="flag">
            <div className="flag-circle">
              <img src="./images/russia (1) 1.png" alt="rus flag" />
            </div>
            <p>RU</p>
          </div>
        </div>

        <div className="flags-container">
          <div className="flag">
            <span className="currency-symbol">₼</span>
            <div className="currency-rate">
              <span>1.00</span>
              <ArrowUpSvg />
            </div>
          </div>
          <div className="flag">
            <span className="currency-symbol">$</span>
            <div className="currency-rate">
              <span>1.00</span>
              <ArrowUpSvg />
            </div>
          </div>
          <div className="flag">
            <span className="currency-symbol">€</span>
            <div className="currency-rate">
              <span>1.00</span>
              <ArrowUpSvg />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default MobilSettingModal;
