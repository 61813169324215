import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxTypes } from '../../type_models/ReduxTypes';
import { changeStateValue } from '../../redux/MainReducer';
import MobileModalTop from './MobileModalTop';

function MobilSelectModal() {
  const modalSelectVisible = useSelector((state: ReduxTypes) => state.Data.modalSelectVisible);
  const dispatch = useDispatch<any>();

  const closeModal = () => {
    if (modalSelectVisible) {
        dispatch(changeStateValue({ name: "modalSelectVisible", value: false }));
    }else{
        dispatch(changeStateValue({ name: "modalSelectVisible", value: true }));
    }
  };

  return (
    
    <div className={modalSelectVisible ? 'background':''}>
        <div
          className="fixed-bottom-modal"
          style={{ bottom: modalSelectVisible? "0" : '-100%' }}
        >
          <MobileModalTop closeModal={closeModal}/>
          <div className="option-list">
            {[...Array(4)].map((_, index) => (
              <label key={index}>
                <input type="radio" name="company" />
                Selected Company {index + 1}
              </label>
            ))}
          </div>
        </div>
    </div>
  );
}

export default MobilSelectModal;
